<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="20" size="35" ref="toppane">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems" dense
					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
					:loading="loading" loading-text="加载中..." show-select single-select>
					<template v-slot:top>
						<div class="d-flex flex-wrap pb-1" style="gap:16px" ref="tabletopbar">
							<v-text-field placeholder="姓名/证件号/手机号" v-model="psearch" clearable hide-details
								append-icon="search" @click:append="loadData" @keyup.enter.native="loadData" style="flex: 0 1 auto" class="ml-2"/>
							<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
								transition="scale-transition" offset-y min-width="290px">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field :value="pdates.join(' ~ ')" label="手术日期" v-on="on" v-bind="attrs" readonly hide-details
										append-icon="mdi-close" @click:append="pdates=[]" style="flex: 0 1 auto;width:240px;"></v-text-field>
								</template>
								<v-date-picker v-model="pdates" @change="menu1=false" range locale="zh-cn" scrollable></v-date-picker>
							</v-menu>
							<v-checkbox v-model="showComplete" label="显示已结算" hide-details class="ml-8" @change="loadData"/>
						</div>
						<v-divider/>
					</template>
					<template v-slot:item.age="{ item }">
						{{getAge(item)}}
					</template>
					<template v-slot:item.opts="{ item }">
						{{getOptsStr(item)}}
					</template>
					<template v-slot:item.progress.operative="{ item }">
						{{formatTime(item.progress.operative)}}
					</template>
					<template v-slot:item.progress.billing="{ item }">
						{{formatTime(item.progress.billing)}}
					</template>
				</v-data-table>
			</pane>
			<pane min-size="20" size="65" v-if="selected.length > 0 && !!selected[0].charges">
				<div style="max-height:100%;background-color:#fff;" class="d-flex overflow-y-auto">
					<div class="pt-2 pl-2">
						<div class="my-2">
							<v-text-field outlined readonly dense hide-details label="计费模板" :value="quotation.name" style="max-width:150px"/>
						</div>
						<v-simple-table id="chargestab">
							<template v-slot:default>
							<thead style="background-color: #eee">
								<tr>
									<th>诊疗项目</th>
									<th>计价说明</th>
									<th>数量</th>
									<th>合计</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(item, index) in quotation.treatment">
								<tr v-if="item.enabled || (!!charges[index] && !!charges[index].quantity)">
									<td width="44%">{{ item.subject }}</td>
									<td width="40%">{{ item.description }}</td>
									<td width="8%">{{ charges[index].quantity }}</td>
									<td width="8%">{{ charges[index].amount }}</td>
								</tr>
								</template>
							</tbody>
							</template>
						</v-simple-table>
						<v-row class="pt-4">
							<v-col><v-text-field label="诊疗合计" :value="selected[0].chargeSum" hide-details readonly/></v-col>
							<v-col><v-select label="折扣" :items="discountList" v-model="discount" hide-details/></v-col>
							<v-col><v-text-field label="诊疗应收" :value="valueAmount" hide-details readonly/></v-col>
						</v-row>
						<v-row>
							<v-col><v-text-field label="其他" v-model.number="other" hide-details/></v-col>
							<v-col/>
							<v-col><v-text-field label="其他应收" :value="other" hide-details readonly/></v-col>
						</v-row>
						<v-row class="pt-4" style="border-top:3px double #ccc">
							<v-col/>
							<v-col/>
							<v-col><v-text-field label="应收合计" :value="valueAmount + other" hide-details readonly/></v-col>
						</v-row>
						<div class="pt-6 pb-4" style="text-align:right">
							<v-btn class="primary" style="width:120px" :loading="loading" :disabled="loading||!canEdit" @click.stop="save">结算并保存</v-btn>
							<v-btn class="primary ml-4" style="width:120px" :disabled="!selected[0].chargeBill" @click.stop="view">查看结算单</v-btn>
						</div>
    	            </div>
	            </div>
			</pane>
		</splitpanes>
		<bill v-model="billDlg" :plan="plan" :quotation="quotation.treatment"/>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, calculateAge} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import Bill from '../components/Bill.vue'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:100},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'套餐内容', value:'opts', width:180, sortable: false},
                    {text:'手术完成时间', value:'progress.operative', width:180},
                    {text:'结算时间', value:'progress.billing', width:180},
                    {text:'结算金额', value:'chargeBill.total', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				options: {sortBy:['progress.operative'], sortDesc:[true]},
				totalItems: 0,
				tableheight: undefined,
				canEdit: true,
				psearch: '',
				tab: 0,
				menu1: false,
				pdates: [],
				quotations: [],
				quotation: {},
                charges: [],
				discountList: [
					{text:'100%', value:1.0},
					{text:'90%', value:0.9},
					{text:'80%', value:0.8},
					{text:'70%', value:0.7},
					{text:'60%', value:0.6},
					{text:'50%', value:0.5},
					{text:'0%', value:0}
				],
				discount: 1.0,
				other: null,
				billDlg: false,
				plan: null,
				showComplete: true,
            }
        },
        created() {
			this.formatTime = formatTime;
        },
        mounted() {
			this.authed = this.$hasPrivilege(['检中结算']);
			if (!this.authed) return;
			this.canEdit = this.$hasPrivilege(['检中结算']);
			this.fetchQuotation();
        },
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 39;
				setTimeout(() => {
    				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			async loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [];
				if (this.psearch) {
					filter.push(_.or([{'pii.username':this.psearch}, {'pii.phone':this.psearch}, {'pii.idcard':this.psearch}]));
				}
				if (this.pdates.length === 2) {
					const d1 = new Date(this.pdates[0] + 'T00:00:00+08:00');
					const d2 = new Date(this.pdates[1] + 'T23:59:59+08:00');
					filter.push({'progress.operative':_.gt(d1).lt(d2)});
				}
				if (!this.showComplete) {
					filter.push({'progress.billing':_.exists(false)})
				}
				filter.push({hide:_.neq(true)});
				filter.push({category:_.neq('clinic')});
				const f = filter;
				this.loading = true;
				try {
                    const countRes = await db.collection('wp3order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp3order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
            getAge(item) {
                return calculateAge(item.pii.dob);
            },
            getOptsStr(item) {
				if (!item.opts) return '';
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
			},
			async fetchQuotation() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('wp3quotation').get();
					this.quotations = res.data;
					this.quotation = this.quotations[0];
				} catch(err) {
					console.error(err);				
				}
			},
			async save() {
                this.loading = true;
				const id = this.selected[0]._id;
				try {
					const res = await this.$callCloudFunc({
						funcname:'billing',
						data: {
							id,
							discount: this.discount,
							other: this.other,
						}
					});
					this.plan = Object.assign(this.selected[0], res.result);
					this.plan.progress.billing = new Date();
					//this.plan = {...this.selected[0], ...res.result};
					await this.fetchData();
					this.billDlg = true;
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('保存失败');
				}
				this.loading = false;
			},
			view() {
				this.billDlg = true;
			},
		},
		computed: {
			valueAmount() {
				if (this.selected.length === 0) return null;
				return this.selected[0].chargeSum * this.discount;
			}
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) {
					this.plan = null;
					return;
				}
				this.plan = newitem[0];
				this.charges = [];
				if (newitem[0].chargeQuotation && newitem[0].charges) {
					const q = this.quotations.find(x => x.name === newitem[0].chargeQuotation);
					if (q) {
						this.quotation = q;
						this.charges = q.treatment.map(x => newitem[0].charges.find(y => y.id === x.id) || {id:x.id, quantity:null});
					}
				}
				if (newitem[0].chargeBill) {
					this.discount = newitem[0].chargeBill.discount;
					this.other = newitem[0].chargeBill.other;
				} else {
					this.discount = 1.0;
					this.other = null;
				}
				const sum = this.charges.reduce((a, v) => a + (v.amount || 0), 0);
				newitem[0].chargeSum = sum;
			},
			pdates(ds) {
				if (ds.length === 1) return;
				if (ds.length === 2) {
					if (new Date(ds[0]) > new Date(ds[1])) {
						[ds[0], ds[1]] = [ds[1], ds[0]];
					}
				}
				this.loadData();
			},
		},
        components: { Splitpanes, Pane, Bill }
    }
</script>

<style>
#chargestab table { border:1px solid #dddddd; width:750px;}
#chargestab table th {height:32px;line-height:1.0;}
#chargestab table td {height:32px;line-height:1.0;}
#chargestab table th + th { border-left:1px solid #dddddd; }
#chargestab table td + td { border-left:1px solid #dddddd; }
</style>